import React from 'react';
import Layout from '../components/layout';

export default () => (
  <Layout>
    <div>
      <h2>Work</h2>
    </div>
  </Layout>
);
